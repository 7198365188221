import '../polyfills';
import '../bootstrap';
import './application.scss';
import '../images/logo.png';
import '../images/puff.svg';

/* Remove media="screen" because otherwise when the stylesheet
      is copied to the iframe by react-to-print, it will be ignored
      because it's not a screen media type. */
document.addEventListener('DOMContentLoaded', () => {
  const linkTags = document.querySelectorAll('link[media="screen"]');
  linkTags.forEach((link) => link.removeAttribute('media'));
});
